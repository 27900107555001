:root {
  --swiper-pagination-color: black;
  --swiper-pagination-bullet-inactive-color: #999999;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-size: 16px;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-navigation-color: #fff;
}

html {
  scroll-behavior: smooth;
}

#featuredCars swiper-slide{
  filter: blur(4px);
}

#featuredCars swiper-slide.swiper-slide-active {
  filter: blur(0);
}

.swiperDown .swiper-slide{
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.swiperDown .swiper-slide-thumb-active {
  opacity: 1;
}


.text-brand-primary {
  color: #ffffff;
}

.bg-brand-primary {
  background-color: #ffffff;
}


.text-brand-secondary {
  color: #0f0f0f;
}

.bg-brand-secondary {
  background-color: #0f0f0f;
}


.text-brand-accent {
  color: #010e2d;
}

.bg-brand-accent {
  background-color: #010e2d;
}



.car-details-sidebar ul li {
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 10px;
}